.references-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 90px);
  padding: 5rem 0;
  background: #073a7d;
}
.references-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.references-title {
  width: 100%;
}
.references-title .rect {
  background-color: #02469e;
  width: 225px;
  height: 15px;
  position: relative;
  bottom: 2rem;
}
.references-title .rect2 {
  margin-left: 2rem;
  background-color: #1f63bc;
}
.references-recs {
  margin: 0 auto;
  width: 80%;
  max-width: 1200px;
}

.reference-img {
  width: 100%;
  background-position: center;
  background-size: cover;
  user-select: none;
}

.zoomed-image-container {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  user-select: none;
}

.reference-img:hover {
  cursor: zoom-in;
}

.zoomed-image-container:hover {
  cursor: zoom-out;
}

.zoomed-image {
  display: block;
  user-select: none;
}

.references-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}
.references-button a {
  text-decoration: none;
}

@media screen and (max-width: 970px) {
  .references-rec {
    width: 250px;
    height: 350px;
  }
}
