.link-btn {
  position: relative;
  transform: translate(0, 0);
  padding: 1rem 2rem;
  border: 1px solid white;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 1rem;
  background: transparent;
}

.link-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  transition: transform 0.1s ease-in;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: top right;
}

.link-btn:hover::after {
  transform: scaleX(1);
  transform-origin: center left;
}

.link-btn:hover {
  cursor: pointer;
  color: #073a7d;
}
