.about-container {
  width: 100%;
  height: calc(100vh - 90px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about-container .left {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-container .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}
.about-container .right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 3rem;
}
.about-container .right h1 {
  font-size: 4rem;
  position: relative;
  z-index: 10;
}
.about-container .right .about-title {
  width: 100%;
  padding-left: 5%;
}
.about-container .right .about-title .rect {
  background-color: #02469e;
  width: 125px;
  height: 15px;
  position: relative;
  bottom: 2rem;
}
.about-container .right .about-title .rect2 {
  margin-left: 1rem;
  background-color: #1f63bc;
}

@media screen and (max-width: 1200px) {
  .about-container {
    height: auto;
    flex-direction: column;
  }
  .about-container .left {
    width: 100%;
    height: 450px;
  }
  .about-container .right {
    width: 100%;
    height: 50%;
    background-position: bottom;
    padding: 1.5rem;
  }
}
