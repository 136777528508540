@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #eeeeee;
  font-family: "Montserrat", sans-serif;
}
body {
  background: radial-gradient(
    circle,
    rgb(11, 57, 109) 0%,
    rgba(5, 41, 87, 1) 100%
  );
}
.textContainer {
  height: calc(100vh - 90px);
  width: 100%;
}
.main-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.textContainer h1,
.textContainer h2 {
  text-align: center;
}
.textContainer h1 {
  font-size: 4rem;
}
.textContainer h2 {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 500;
}

@media screen and (max-width: 500px) {
  .textContainer h1 {
    font-size: 2.5rem;
  }
  .textContainer h2 {
    font-size: 1.5rem;
  }
}
