@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");
*::after,
*::before,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Montserrat", sans-serif;
}
header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.header-container {
  height: 90px;
}
.header.active {
  background-color: rgb(9, 52, 109);
}
.header-container .nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 4rem 1rem 4rem;
}
.header-container .nav img {
  max-width: 80px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.header-container ul {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  list-style: none;
}
.header-container li {
  margin-left: 2.5rem;
  list-style: none;
}
.header-container a {
  font-size: 1.2rem;
  transition: opacity 0.3s ease-in-out;
  text-decoration: none;
}
.hamburger-menu {
  display: none;
}

@media screen and (max-width: 1140px) {
  .header-container .nav {
    justify-content: space-between;
  }
  .header-container .nav img {
    max-width: 75px;
  }
  .header-container .nav-links {
    flex-direction: column;
    width: 100%;
    height: 100vh;
    margin: 0;
    background-color: #052957;
    position: absolute;
    top: 0;
    opacity: 0;
    left: -110%;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-out;
    z-index: 9999;
  }
  .header-container li {
    margin-left: 0rem;
  }
  .header-container li a {
    margin-top: 0.5rem;
  }
  .hamburger-menu {
    display: block;
    z-index: 10;
    cursor: pointer;
  }
  .header-container .nav-links.active {
    left: 0;
    transition: 0.3s ease-in-out;
    z-index: 1;
    opacity: 1;
  }
  .nav-links.collapse {
    left: 0;
  }
}
