/* Footer */

.footer-conatiner {
  background: #052957;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 0.9rem;
  padding: 3rem 0;
}

.footer-conatiner div {
  max-width: 260px;
  margin: 2rem 4rem;
}

.footer-conatiner div h2 {
  text-align: center;
  margin-bottom: 0.5rem;
}

.nav-links {
  margin-left: 0.5rem;
}

.nav-links li a:hover {
  color: #d4d3d3;
}

.contact li a:hover {
  color: #d4d3d3;
}

.about-us {
  text-align: center;
}

.copyright {
  color: #eeeeee;
  background: #07336e;
  padding: 1.5rem 0;
  text-align: center;
  font-size: 1.1rem;
}

li {
  display: flex;
  align-items: center;
  margin: 0.8rem 0;
}

li span {
  margin-left: 7px;
}

.fontAwesomeIcon {
  font-size: 1.7rem;
}
