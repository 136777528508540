/* Map */

.leaflet-container {
  display: block;
  width: 100%;
  height: 70vh;
}

.tooltip-label{
  color: #707070;
  font-size: 1rem;
  font-weight: 500;
}
