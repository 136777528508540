html,
body {
  width: 100%;
  height: 100%;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  width: 100%;
  height: 100%;
  font: normal 75% Arial, Helvetica, sans-serif;
}

canvas {
  width: 100%;
  height: 100%;
  display: block;
}

/* ---- particles.js container ---- */

#tsparticles {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
