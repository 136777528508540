@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap);
/* Footer */

.footer-conatiner {
  background: #052957;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 0.9rem;
  padding: 3rem 0;
}

.footer-conatiner div {
  max-width: 260px;
  margin: 2rem 4rem;
}

.footer-conatiner div h2 {
  text-align: center;
  margin-bottom: 0.5rem;
}

.nav-links {
  margin-left: 0.5rem;
}

.nav-links li a:hover {
  color: #d4d3d3;
}

.contact li a:hover {
  color: #d4d3d3;
}

.about-us {
  text-align: center;
}

.copyright {
  color: #eeeeee;
  background: #07336e;
  padding: 1.5rem 0;
  text-align: center;
  font-size: 1.1rem;
}

li {
  display: flex;
  align-items: center;
  margin: 0.8rem 0;
}

li span {
  margin-left: 7px;
}

.fontAwesomeIcon {
  font-size: 1.7rem;
}

/* Contact */

.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: top;
  font-size: 1.05rem;
  min-height: 70vh;
  font-weight: 600;
}

.contact-info {
  padding: 6rem 2rem;
}

.contact-info-list li .fontAwesomeIcon {
  font-size: 2.3rem;
}

.contact-info-list li {
  margin: 1rem 0;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.contact-info-list li span p {
  font-weight: 400;
}

.contact-info-list li a:hover {
  color: #d4d3d3;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 6rem 0;
  position: relative;
}

input,
textarea {
  width: 80%;
  padding: 0.6rem 0.6rem;
  margin: 0.6rem 0.5rem;
  font-size: 1rem;
  color: #707070;
  border: none;
  outline: none;
  border-radius: 4px;
  resize: none;
}

input:focus,
textarea:focus {
  border: none;
  outline: none;
}

button[type="submit"] {
  margin: 0.8rem 0;
}

.textArea {
  display: flex;
  justify-content: center;
  position: relative;
}

.charCount {
  position: absolute;
  top: 80%;
  right: 13%;
  z-index: 99;
  color: #707070;
}

.error-msg {
  color: #cc8719;
  font-size: 1rem;
  font-weight: 600;
}

.success-msg {
  color: #52cc2d;
  font-size: 1rem;
  font-weight: 600;
  margin: 1rem;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  font-size: 2rem;
  z-index: 999;
}

/* Loading spinner */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media screen and (max-width: 904px) {
  .contact-info {
    padding: 2rem 2rem;
  }

  form {
    padding: 2rem 0;
  }
}

/* Map */

.leaflet-container {
  display: block;
  width: 100%;
  height: 70vh;
}

.tooltip-label{
  color: #707070;
  font-size: 1rem;
  font-weight: 500;
}

*::after,
*::before,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Montserrat", sans-serif;
}
header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.header-container {
  height: 90px;
}
.header.active {
  background-color: rgb(9, 52, 109);
}
.header-container .nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 4rem 1rem 4rem;
}
.header-container .nav img {
  max-width: 80px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.header-container ul {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  list-style: none;
}
.header-container li {
  margin-left: 2.5rem;
  list-style: none;
}
.header-container a {
  font-size: 1.2rem;
  transition: opacity 0.3s ease-in-out;
  text-decoration: none;
}
.hamburger-menu {
  display: none;
}

@media screen and (max-width: 1140px) {
  .header-container .nav {
    justify-content: space-between;
  }
  .header-container .nav img {
    max-width: 75px;
  }
  .header-container .nav-links {
    flex-direction: column;
    width: 100%;
    height: 100vh;
    margin: 0;
    background-color: #052957;
    position: absolute;
    top: 0;
    opacity: 0;
    left: -110%;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-out;
    z-index: 9999;
  }
  .header-container li {
    margin-left: 0rem;
  }
  .header-container li a {
    margin-top: 0.5rem;
  }
  .hamburger-menu {
    display: block;
    z-index: 10;
    cursor: pointer;
  }
  .header-container .nav-links.active {
    left: 0;
    transition: 0.3s ease-in-out;
    z-index: 1;
    opacity: 1;
  }
  .nav-links.collapse {
    left: 0;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #eeeeee;
  font-family: "Montserrat", sans-serif;
}
body {
  background: radial-gradient(
    circle,
    rgb(11, 57, 109) 0%,
    rgba(5, 41, 87, 1) 100%
  );
}
.textContainer {
  height: calc(100vh - 90px);
  width: 100%;
}
.main-title {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.textContainer h1,
.textContainer h2 {
  text-align: center;
}
.textContainer h1 {
  font-size: 4rem;
}
.textContainer h2 {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 500;
}

@media screen and (max-width: 500px) {
  .textContainer h1 {
    font-size: 2.5rem;
  }
  .textContainer h2 {
    font-size: 1.5rem;
  }
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  width: 100%;
  height: 100%;
  font: normal 75% Arial, Helvetica, sans-serif;
}

canvas {
  width: 100%;
  height: 100%;
  display: block;
}

/* ---- particles.js container ---- */

#tsparticles {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.about-container {
  width: 100%;
  height: calc(100vh - 90px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about-container .left {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-container .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}
.about-container .right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 3rem;
}
.about-container .right h1 {
  font-size: 4rem;
  position: relative;
  z-index: 10;
}
.about-container .right .about-title {
  width: 100%;
  padding-left: 5%;
}
.about-container .right .about-title .rect {
  background-color: #02469e;
  width: 125px;
  height: 15px;
  position: relative;
  bottom: 2rem;
}
.about-container .right .about-title .rect2 {
  margin-left: 1rem;
  background-color: #1f63bc;
}

@media screen and (max-width: 1200px) {
  .about-container {
    height: auto;
    flex-direction: column;
  }
  .about-container .left {
    width: 100%;
    height: 450px;
  }
  .about-container .right {
    width: 100%;
    height: 50%;
    background-position: bottom;
    padding: 1.5rem;
  }
}

.references-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 90px);
  padding: 5rem 0;
  background: #073a7d;
}
.references-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.references-title {
  width: 100%;
}
.references-title .rect {
  background-color: #02469e;
  width: 225px;
  height: 15px;
  position: relative;
  bottom: 2rem;
}
.references-title .rect2 {
  margin-left: 2rem;
  background-color: #1f63bc;
}
.references-recs {
  margin: 0 auto;
  width: 80%;
  max-width: 1200px;
}

.reference-img {
  width: 100%;
  background-position: center;
  background-size: cover;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.zoomed-image-container {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.reference-img:hover {
  cursor: zoom-in;
}

.zoomed-image-container:hover {
  cursor: zoom-out;
}

.zoomed-image {
  display: block;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.references-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}
.references-button a {
  text-decoration: none;
}

@media screen and (max-width: 970px) {
  .references-rec {
    width: 250px;
    height: 350px;
  }
}

.link-btn {
  position: relative;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  padding: 1rem 2rem;
  border: 1px solid white;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 1rem;
  background: transparent;
}

.link-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  transition: -webkit-transform 0.1s ease-in;
  transition: transform 0.1s ease-in;
  transition: transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
  z-index: -1;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: top right;
          transform-origin: top right;
}

.link-btn:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: center left;
          transform-origin: center left;
}

.link-btn:hover {
  cursor: pointer;
  color: #073a7d;
}

.equipment-container {
  width: 100%;
  height: calc(100vh - 90px);
  display: grid;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.equipment-container .right {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.equipment-container .right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}
.equipment-container .left {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 3rem 0;
  padding: 0 5%;
}
.equipment-container .left h1 {
  font-size: 4rem;
  position: relative;
  z-index: 10;
}
.equipment-container .left .equipment-title {
  width: 100%;
  padding-left: 5%;
}
.equipment-container .left .equipment-title .rect {
  background-color: #02469e;
  width: 125px;
  height: 15px;
  position: relative;
  bottom: 2rem;
}
.equipment-container .left .equipment-title .rect2 {
  margin-left: 1rem;
  background-color: #1f63bc;
}

@media screen and (max-width: 1200px) {
  .equipment-container {
    height: auto;
    flex-direction: column-reverse;
  }
  .equipment-container .right {
    width: 100%;
    height: 450px;
  }
  .equipment-container .left {
    width: 100%;
    height: 50%;
    background-position: bottom;
    padding: 1.5rem;
  }
}

.gallery-container{
  margin: 5rem auto;
  width: 75vw;
}

.gallery-title{
  text-align: center;
  margin: 4rem 0;
  font-size: 2.3rem;
}

.gallery-content{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}

@media screen and ( max-width: 1050px) {
  .gallery-content{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and ( max-width: 500px) {
  .gallery-content{
    grid-template-columns: 1fr;
  }
}
.offer-container {
  min-height: calc(100vh - 90px);
  width: 100%;
  padding: 5rem 0;
  background: #073a7d;
}
.offer-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.offer-title .rect {
  background-color: #03306b;
  width: 275px;
  height: 15px;
  position: relative;
  bottom: 2rem;
}
.offer-title .rect2 {
  margin-left: 2rem;
  background-color: #1f63bc;
}
.icons-container {
  display: grid;
  width: 75%;
  height: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, -0%);
          transform: translate(-50%, -0%);
}
.icon-circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.icon-circle {
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 25px -5px rgba(254, 254, 255, 0.5);
  border-radius: 100%;
  margin: 2rem 0rem 1rem 0rem;
}
.icon-text {
  max-width: 350px;
  text-align: center;
}
.icon-text h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
}
.icon {
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
}
.bigger-icon {
  width: 45px;
}
.smaller-icon {
  width: 50px;
}
@media screen and (max-width: 1400px) {
  .icons-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 850px) {
  .icons-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

body {
  font-weight: 300;
  color: #eeeeee;
}

h2 {
  font-weight: 400;
  font-size: 2rem;
}

a {
  text-decoration: none;
  color: #eeeeee;
  margin: 0 !important;
}

ul {
  list-style-type: none;
}

iframe {
  display: none;
}

body iframe {
  display: inline-block;
}

.section-title {
  font-size: 2.5rem;
  position: relative;
  z-index: 10;
}

.section-content {
  font-size: 1.3rem;
}

.grecaptcha-badge {
  display: none !important;
}
